<template>
	<SubPageHeader :headerTitle="getHeaderTitle"></SubPageHeader>
	<div class="content">
		<div class="container-fluid">
			<component :is="$store.state.component" :uniqueId="0">

			</component>
		</div>
	</div>
</template>
<script>
import SubPageHeader from '../SubPageHeader.vue'
import AllPatientActiveTaskList from '../../components/patient/echart/activeTaskList.vue';
import AllPatientCompleteDeleteTaskList from '../../components/patient/echart/completeDeleteTaskList.vue';

export default {
	components: {
		SubPageHeader,
		AllPatientActiveTaskList,
		AllPatientCompleteDeleteTaskList,

	},
	created() {
		this.$store.state.component = this.$route.name;
	},
	computed: {
		getHeaderTitle() {
			const currentRoute = this.$route;
			if (currentRoute.meta && currentRoute.meta.headerTitle) {
				return currentRoute.meta.headerTitle;
			} else {
				const parentRoute = currentRoute.matched[0];
				if (parentRoute.meta && parentRoute.meta.headerTitle) {
					return parentRoute.meta.headerTitle;
				} else {
					return 'Default Header Title';
				}
			}
		},
	},
}
</script>